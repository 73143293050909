.message{
    position: fixed;
    top: 50px;
    right: 50px;
    z-index: 222;
    padding: 20px;
    border-radius: 20px;
    color: #fff;
    font-size: 12px;
    max-width: 250px;
    background: #333333;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
}