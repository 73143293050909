
*{
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; 
  outline: none;
}

/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; 
}

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote {
  &:before, &:after {
    content: '';
    content: none; } }

q {
  &:before, &:after {
    content: '';
    content: none; } }

table {
  border-collapse: collapse;
  border-spacing: 0; }
a{
  text-decoration: none;
  display: block;
  cursor: pointer;
  width: fit-content;
}
*{
  box-sizing: border-box;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar { 
      width: 0 !important;
      display: none; 
    }
}
button{
  background: none;
  border: none;
  cursor: pointer;
}
input{
  letter-spacing: 0.07em;
}