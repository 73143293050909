@media(max-width:1439.9px){
    .menu{
        bottom: -12px;
        right: 2vw;
        li{
            padding-top: 10px;
            button{
                padding: 10px 20px;
            }
        }
    }
}
@media(max-width:1199.9px){
    h3{
        font-size: 24px;
    }
    .about_right_img{
        margin: 0 auto;
        margin-bottom: 10px;
    }
    .page_wraper_content{
        padding-top: 150px;
    }
}
@media(max-width:991.9px){
    .react-tabs__tab-list li{
        font-size: 16px;
    }
    .how_works{
        padding-top: 100px;
    }
}
@media(max-width:767.9px){
    .p_20{
        padding: 15px 20px !important;
    }
    .logo{
        display: none;
    }
    .menu{
        transform: translateX(-100vw);
        transition: transform 0.3s linear;
        left: 0;
        top: 0;
        right: auto;
        bottom: auto;
        width: 100%;
        height: 100%;
        border: 1px solid rgba(33, 33, 33, 0.5);
        padding-bottom: 10vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        &:after{
            display: none;
        } 
        li{
            justify-content: flex-start;
            margin-left: -3px;
            background-color: #fff;
            button{
                color:#212121;
                font-size: 18px;
            }
            &:after{
                display: none;
            }
        }
        &.menu_active{
            transform: none;
        }
       
    }
    // delete
    .projects_list{
        background-color: #fff;
        width: calc(100% - 3px);
        z-index: 10;
        height: calc((var(--vh, 1vh) * 100) - 33px);
        position: absolute;
        bottom: 1px;
        left: 1px;
        overflow: hidden;
        padding-top: 62px;
        transform: translateY(100vh);
        transition: all 0.3s linear;
        &_active{
            transform: none;
        }
        .canv{
            position: relative;
            bottom: -64px;
        }
    }
    .page_wraper_content{
        position: static;
    }
    .menu_btn{
        display: block ;
    }
   
.page_wraper:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 10vh;
    width: 3px;
    height: 20vh;
    background-color: #fff;
    z-index: 2;
}
.page_wraper_content{
    padding-left: 40px;
    padding-top: 150px;
}
.how_it_works_item{
    width: 105px;
    height: 105px;
    font-size: 10px;
    line-height: 14px;
}
.info_2{
    margin-top: -30px;
}
.info_block{
    svg{
        width: 50px;
    }
}
.dot{
    width: 8px;
    height: 8px;
    &_wrap{
        width: 25px;
        height: 30px;
    }
    &:after{
        width: calc(100% + 3px);
        height: calc(100% + 3px);
    }
}

.how_works_block > div:hover .dot{
    width: 14px;
    height: 14px;
    &:after{
        width: calc(100% + 6px);
        height: calc(100% + 6px);
    }
}
.page_title{
    font-size: 24px;
}
}
@media(max-width:575.9px){
    .p_20{
        height: calc(var(--vh, 1vh) * 100);
    }
    h1{
        font-size: 35px;
    }
    h3{
        font-size: 18px;
    }
    .home_left {
        margin-top: 20px;
        h3,p{
        padding-left: 25px;
        }
    }
    .page_wraper_content{
        padding-left: 30px;
    }
    .page_title{
        font-size: 20px;
        top: 50px;
    }
    .left_content{
        padding-right: 10px;
    }
    .home_page{
        .left_content{
            margin-bottom: 30px;
        }
    }
    .overflow_wrap{
        padding-bottom: 0;
    }
    .how_works{
        padding-top: 20px;
        &_rows{
            margin-left: -30px;
        }
        &_info{
            margin-bottom: 20px;
            font-size: 12px;
            padding-left: 20px;
            height: 75px;
        }
    }
    .how_it_works_item{
        width: 90px;
        height: 90px;
        font-size: 9px;
        line-height: 12px;
    }
    .how_works_block .dot_wrap{
        margin-bottom: 5px;
        width: 30px;
        height: 20px;
    }
    .for_creators_page{
        .react-tabs__tab-list li{
            font-size: 14px;
            &:nth-child(1){
                order: 1;
            } 
            &:nth-child(2){
                order: 3;
            }
            &:nth-child(3){
                order: 2;
            }
            &:nth-child(4){
                order: 2;
            }
        }

    }
    .for_investors_page{
        .react-tabs__tab-list li{
            font-size: 14px;
            &:nth-child(1){
                order: 1;
            } 
            &:nth-child(2){
                order: 3;
            }
            &:nth-child(3){
                order: 8;
            }
            &:nth-child(4){
                order: 2;
            }
            &:nth-child(5){
                order: 5;
            }
            &:nth-child(6){
                order: 4;
            }
            &:nth-child(7){
                order: 7;
            }
        }

    }
    .menu{
        li{
            padding-left: 10px;
        }
    }
  
    .about_us_2{
        .overflow_wrap{
            max-height: calc((var(--vh, 1vh) * 100) - 272px);
            padding-bottom: 10px;
        }
    }
    .about_right_img{
        width: 80px;
        height: 80px;
    }
    .about_us_2{
        .overflow_wrap p{
            padding-bottom: 30px;
        }
    }
    .about_us_1{
        .overflow_wrap pre{
            padding-bottom: 10px;
        }
    }
    .projects_list{
        display: flex;
        align-items: flex-end;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: flex-end;
        .canv{
            position: relative;
            height: calc((var(--vh, 1vh) * 100) - 100px);
            width: 100%;
            canvas{
                height: calc((var(--vh, 1vh) * 100) - 100px);
            }
        }
    }
    .input_field_wrap{
        margin-bottom: 30px;
    }
}
