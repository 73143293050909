@import './reset';
@import './mixins&vars';

@font-face {
    font-family: "Comfortaa', sans-serif";
    src: local("Comfortaa"),
      url("../fonts/Comfortaa/Comfortaa-Regular.ttf") format("truetype");
    font-weight: normal;
  }
  @font-face {
    font-family: "Comfortaa', sans-serif";
    src: local("Comfortaa"),
      url("../fonts/Comfortaa/Comfortaa-Medium.ttf") format("truetype");
    font-weight: 500;
  }
  @font-face {
    font-family: "Comfortaa', sans-serif";
    src: local("Comfortaa"),
      url("../fonts/Comfortaa/Comfortaa-SemiBold.ttf") format("truetype");
    font-weight: 600;
  }
  @font-face {
    font-family: "Comfortaa', sans-serif";
    src: local("Comfortaa"),
      url("../fonts/Comfortaa/Comfortaa-Bold.ttf") format("truetype");
    font-weight: bold;
  }
  body{
    font-family: "Comfortaa', sans-serif";
    line-height: 150%;
    font-size: 14px;
    font-weight: normal;
    color: $text_color;
    *{
      font-family: "Comfortaa', sans-serif";
      font-weight: normal;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
      &:-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
    }
    a{
      color: #212121;
    }
}
h1{
    font-size: 65px;
    line-height: 130%;
}
h2{
    font-size: 60px;
    line-height: 130%;
}
h3{
    font-size: 35px;
    line-height: 130%; 
}
h4{
    font-size: 26px;
    line-height: 130%;
}
h5{
    font-size: 20px;
    line-height: 130%;
}
.vertical_line{
   @include vertical_line;
}
.horizontal_line{
    @include horizontal_line;
    width: 100%
}
.line_45{
    @include horizontal_line;
    transform: rotate(-45deg);
    width: 100%
}
.d_flex{
  display: flex;
}
.align_center{
  display: flex;
  align-items: center;
}
.just_center{
  display: flex;
  justify-content: center;
}
.just_btwn{
  display: flex;
  justify-content: space-between;
}
.list_style{
  li{
    position: relative;
    padding-left: 35px ;
    margin-bottom: 25px;
    &:after{
      content: '';
      position: absolute;
      left: 0;
      top: 2px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #FFF5F5;
    }
  }
}
.btn_1{
  @include round_btn;
  text-align: center;
  &:after{
    border-width: 2px !important;
  }
}
.triangle{
  width: 0; 
  height: 0; 
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 11px solid #FF6D6D;
  transition: all 0.3s ease-in-out;
}
.scale_arr{
  transform: scale(-1,1);
}
.log_out{
  position: absolute !important;
  right:20px;
  top:20px;
  font-size: 12px;
  background-color: #FFE2E2;
  z-index:100;
}

// 


@import './media';